.LogItem {
  max-width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms;

  &--open {
    opacity: 1;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
  }

  &__icon {
    width: 1rem;
    height: 1.6rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
    flex-grow: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title,
  &__link {
    font-size: 1rem;
    line-height: 1.25rem;
    word-break: break-word;
  }

  &__link {
    padding: 1rem;
    padding-top: 0;
    margin-top: -0.8rem;
  }

  &__body {
    padding: 1rem;
    font-weight: var(--font-weight-normal);
    font-family: var(--font-family-monospace);
    font-size: 0.875rem;
    line-height: 1.375rem;
    word-break: break-word;
  }

  // Light box
  &--instruction,
  &--error,
  &--warning {
    width: fit-content;
    background-color: var(--pal-background-primary);
    border: 1px solid var(--pal-border-primary);
    box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(var(--pal-shadow-rbg), 0.08);
    color: var(--pal-text-primary);

    p {
      color: var(--pal-text-primary);
    }

    .LogItem__body {
      border-top: 1px solid var(--pal-border-primary);
      color: var(--pal-text-primary);
    }
  }

  // Dark box
  &--request,
  &--response {
    width: 100%;
    background-color: var(--pal-example-code);
    color: var(--pal-brand-primary-on);

    p {
      color: var(--pal-brand-primary-on);
    }

    .LogItem__title {
      font-weight: var(--font-weight-medium);
      color: var(--pal-brand-primary-on);
    }

    .LogItem__body {
      // Hard coded color, but it's OK. It does not change between light and
      // dark theme.
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      color: var(--pal-brand-primary-on);
    }

    code {
      // Hard coded colors, but it's OK. They do not change between light and
      // dark theme.
      color: #ffdd96;
      background-color: rgba(255, 221, 150, 0.08);
      border-color: rgba(255, 221, 150, 0.16);
    }
  }

  // Instruction
  &--instruction {
    .LogItem__icon svg {
      stroke: var(--pal-success);
    }
  }

  // Error
  &--error {
    .LogItem__icon svg {
      stroke: var(--pal-error);
    }
  }

  &--warning {
    .LogItem__icon svg {
      stroke: var(--pal-warning);
    }
  }

  // Request
  &--request {
    .LogItem__icon svg {
      stroke: var(--pal-warning);
    }
  }

  // Response
  &--response {
    .LogItem__icon svg {
      stroke: var(--pal-success);
    }
  }
}
